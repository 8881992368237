import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quickinfos" }

import { ref, onMounted, watch, computed } from 'vue';
import { useStore } from '@/components/common/store';
import ClimateChart from '@lmt-rpb/ClimateChart/ClimateChart.vue';
import RegionFacts from '@lmt-rpb/RegionFacts/RegionFacts.vue';
import MapPreviewAndModal from '@lmt-rpb/MapPreviewAndModal/MapPreviewAndModal.vue';
import HotelMap from '@lmt-rpb/Molecules/HotelMap/HotelMap.vue';
import type { StaticMaps } from '@/interfaces/google-maps';
import type { DestinationFacts, ClimateData } from '@/interfaces/quickinfo-types';
import type { ApiDataTypes, ApiDataTypesKeys } from '@/components/common/types';
import { gaSelectContentAttr } from 'src/utils/analytics';


export default /*@__PURE__*/_defineComponent({
  __name: 'Quickinfos',
  props: {
    destinationData: {},
    climateData: {},
    staticMaps: {},
    climateRegion: {},
    northwest: {},
    southeast: {},
    lat: {},
    lng: {},
    zoom: {}
  },
  setup(__props: any) {



const store = useStore();
const hotelRequestBody = ref<ApiDataTypes>();
const isHotelOnly = computed(() => store.state.searchMask.onlyHotel);

const apiData = store.getters['searchMask/apiData'];

function updateHotelRequestBody() {
	const exclude: ApiDataTypesKeys[] = isHotelOnly.value ? ['DepartureAirport', 'DirectFlight', 'Transfer'] : [];
	exclude.push('DefiniteStartDate', 'TourOperatorCodes', 'HotelIffCode');
	const travelType = isHotelOnly.value ? 'Accommodation' : 'Package';
	hotelRequestBody.value = apiData({ travelType }, exclude) as ApiDataTypes;
}

const gaAttributes = gaSelectContentAttr({
	cId: 'quickinfos_regionmap',
	cType: 'ux_regionpage_click',
});

watch(
	() => store.state.searchMask.travelDuration,
	() => {
		updateHotelRequestBody();
	},
	{ once: true }
);

onMounted(() => {
	updateHotelRequestBody();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(ClimateChart, {
      "climate-data": _ctx.climateData,
      "climate-region": _ctx.climateRegion
    }, null, 8 /* PROPS */, ["climate-data", "climate-region"]),
    _createVNode(RegionFacts, { "destination-data": _ctx.destinationData }, null, 8 /* PROPS */, ["destination-data"]),
    _createVNode(MapPreviewAndModal, {
      id: "hotel-info-map",
      class: "hotel-infos__section-google-maps",
      lat: _ctx.lat,
      lng: _ctx.lng,
      "is-hotel-page": false,
      "maps-key": 'AIzaSyDBc2xkj5NW4H9Llr0x4XsQ6zPkO5hDa7I',
      marker: false,
      northwest: _ctx.northwest,
      "show-note": false,
      southeast: _ctx.southeast,
      "static-maps": _ctx.staticMaps,
      title: _ctx.destinationData.destinationName,
      zoom: _ctx.zoom,
      "hotel-request-body": hotelRequestBody.value,
      "open-fullscreen": "true"
    }, {
      default: _withCtx(({ loadFullscreen }) => [
        _createVNode(HotelMap, {
          "ga-attributes": _unref(gaAttributes),
          onClickHotelMap: ($event: any) => (loadFullscreen())
        }, null, 8 /* PROPS */, ["ga-attributes", "onClickHotelMap"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["lat", "lng", "northwest", "southeast", "static-maps", "title", "zoom", "hotel-request-body"])
  ]))
}
}

})