<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<section class="quickinfos">
		<ClimateChart
			:climate-data="climateData"
			:climate-region="climateRegion"
		/>
		<RegionFacts :destination-data="destinationData" />
		<MapPreviewAndModal
			id="hotel-info-map"
			class="hotel-infos__section-google-maps"
			:lat="lat"
			:lng="lng"
			:is-hotel-page="false"
			:maps-key="'AIzaSyDBc2xkj5NW4H9Llr0x4XsQ6zPkO5hDa7I'"
			:marker="false"
			:northwest="northwest"
			:show-note="false"
			:southeast="southeast"
			:static-maps="staticMaps"
			:title="destinationData.destinationName"
			:zoom="zoom"
			:hotel-request-body="hotelRequestBody"
			open-fullscreen="true"
		>
			<template #default="{ loadFullscreen }">
				<HotelMap
					:ga-attributes="gaAttributes"
					@click-hotel-map="loadFullscreen()"
				/>
			</template>
		</MapPreviewAndModal>
	</section>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useStore } from '@/components/common/store';
import ClimateChart from '@lmt-rpb/ClimateChart/ClimateChart.vue';
import RegionFacts from '@lmt-rpb/RegionFacts/RegionFacts.vue';
import MapPreviewAndModal from '@lmt-rpb/MapPreviewAndModal/MapPreviewAndModal.vue';
import HotelMap from '@lmt-rpb/Molecules/HotelMap/HotelMap.vue';
import type { StaticMaps } from '@/interfaces/google-maps';
import type { DestinationFacts, ClimateData } from '@/interfaces/quickinfo-types';
import type { ApiDataTypes, ApiDataTypesKeys } from '@/components/common/types';
import { gaSelectContentAttr } from 'src/utils/analytics';

defineProps<{
	destinationData: DestinationFacts;
	climateData: ClimateData;
	staticMaps: StaticMaps;
	climateRegion: string;
	northwest: { lat: number; lng: number } | null;
	southeast: { lat: number; lng: number } | null;
	lat: number;
	lng: number;
	zoom: number;
}>();

const store = useStore();
const hotelRequestBody = ref<ApiDataTypes>();
const isHotelOnly = computed(() => store.state.searchMask.onlyHotel);

const apiData = store.getters['searchMask/apiData'];

function updateHotelRequestBody() {
	const exclude: ApiDataTypesKeys[] = isHotelOnly.value ? ['DepartureAirport', 'DirectFlight', 'Transfer'] : [];
	exclude.push('DefiniteStartDate', 'TourOperatorCodes', 'HotelIffCode');
	const travelType = isHotelOnly.value ? 'Accommodation' : 'Package';
	hotelRequestBody.value = apiData({ travelType }, exclude) as ApiDataTypes;
}

const gaAttributes = gaSelectContentAttr({
	cId: 'quickinfos_regionmap',
	cType: 'ux_regionpage_click',
});

watch(
	() => store.state.searchMask.travelDuration,
	() => {
		updateHotelRequestBody();
	},
	{ once: true }
);

onMounted(() => {
	updateHotelRequestBody();
});
</script>

<style lang="scss" scoped>
$quickinfo-element-max-width: 45.6rem;
$quickinfo-gap-normal: 3.1rem;
$quickinfo-gap-tablet: 2.5rem;

.quickinfos {
	max-width: 144rem;
	margin: 0 auto $vgrid-mobile-large;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: $quickinfo-gap-normal;

	.climate-chart,
	.region-facts {
		box-shadow: $box-shadow;
	}

	.climate-chart,
	.region-facts,
	:deep(.hotel-map__container) {
		border-radius: $border-radius-large;
		max-width: $quickinfo-element-max-width;
		min-width: 25rem;
		height: 37.7rem;
		min-height: 37.7rem;
	}

	.region-facts {
		padding: 0 2.5rem;
		height: auto;
	}
}

@media (min-width: $breakpoint-small) {
	.quickinfos {
		margin-bottom: $vgrid-desktop-large;
	}
}

@media (min-width: $breakpoint-medium) {
	.quickinfos {
		gap: $quickinfo-gap-tablet;

		.climate-chart {
			width: 100%;
			max-width: unset;
			flex-basis: calc((#{$quickinfo-element-max-width} * 2) + #{$quickinfo-gap-tablet});
		}

		.region-facts {
			height: 37.7rem;
		}
	}
}

@media (min-width: $breakpoint-large) {
	.quickinfos {
		gap: $quickinfo-gap-normal;
	}
}

@media (max-width: $breakpoint-verysmall) {
	:deep(.hotel-map__container svg) {
		transform: scale(1.5);
		transition: all 0.2s ease-out;
	}
}

@media (min-width: $breakpoint-verylarge) {
	.quickinfos {
		.climate-chart {
			max-width: $quickinfo-element-max-width;
		}
	}
}
</style>
